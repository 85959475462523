<template>
  <el-menu
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :default-openeds="defaultOpenedsArray"
    :unique-opened="true"
    :collapse-transition="false"
    router
    default-active="2"
  >
    <el-submenu index="1" :unique-opened="false">
      <template slot="title">
        <div class="icons" @click="clickFn">
          <!-- <i class="icon iconfont icon-caidan" v-if="state"></i> -->
          <i class="el-icon-s-unfold" v-if="state"></i>
          <i class="el-icon-close" v-else></i>
        </div>
      </template>
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/zx">资讯动态</el-menu-item>
      <el-submenu index="1-1">
        <span slot="title">产品中心</span>
        <el-menu-item index="/tea">茶产业</el-menu-item>
        <el-menu-item index="/health">消费品</el-menu-item>
        <el-menu-item index="/society">政府/协会</el-menu-item>
        <el-menu-item index="/industry">工业领域</el-menu-item>
        <el-menu-item index="/hygiene">医疗卫生</el-menu-item>
      </el-submenu>
      <el-submenu index="1-2">
        <span slot="title">项目案例</span>
        <el-menu-item index="/teaCase">茶企数字化案例</el-menu-item>
        <el-menu-item index="/teaCaseWeb">区域茶产业管理服务平台</el-menu-item>
        <el-menu-item index="/healthCase">消费品行业案例</el-menu-item>
        <el-menu-item index="/factoryCase">工厂智能化案例</el-menu-item>
        <el-menu-item index="/hygieneCase">医疗卫生行业案例</el-menu-item>
      </el-submenu>
      <el-menu-item index="/home">关于我们</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
export default {
  data() {
    return {
      state: true,
      defaultOpenedsArray: [],
    };
  },
  methods: {
    clickFn() {
      this.state = !this.state;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu-item {
  border-bottom: 0.02rem rgba(255, 255, 255, 0.5) solid;
  background-color: #0c3064;
  color: #fff;
  font-size: 18px;
}
.el-menu {
  border-right: none;
}
::v-deep .el-submenu {
  width: 19.5rem;
  border-bottom-color: rgba(255, 255, 255, 0.5);
  .el-menu {
    background-color: #0c3064;
    .is-active {
      background-color: #0c3064;
    }
    .el-submenu__title {
      background-color: #0c3064 !important;
      font-size: 18px;
      border-bottom-color: rgba(255, 255, 255, 0.5);
    }
  }
}
::v-deep .el-icon-arrow-down {
  color: #fff;
  margin-right: 1.066rem;
}
::v-deep .el-submenu__title {
  height: 53px;
  line-height: 53px;
  width: 19.5rem;
  color: #fff;
  border-bottom: 0.05rem rgba(255, 255, 255, 0.5) solid;
  padding: 0 !important;
  // margin-left: 20px;
  .icons {
    position: relative;
    height: 2.76rem;
    width: 100%;
    i {
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
  span {
    margin-left: 40px;
  }
}
</style>
